.image {
  width: 100%;
  height: auto;
}
.containerStyle {
  position: relative;
  text-align: center;
}
.textStyle,
.imageText {
  font-weight: 600;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.textStyle {
  font-size: 3.5vw;
}
.secondText {
  font-size: 0.7em;
}

.imageText {
  padding: 10px;
  font-size: 1.1em;
}
.poppins,
.Developmenttext,
.heading,
.stage,
.title,
.offeringDesc,
.differentiatorsCard {
  font-family: "Poppins", sans-serif;
}
.Developmenttext {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 176px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 21%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
  font-size: 1.4em;
  color: white;
  border-radius: 5px;
}
.heading {
  font-size: 1.7em;
  text-decoration: underline;
}
.title {
  font-size: 1.6rem;
  font-weight: 500;
}
.offering {
  cursor: pointer;
  background: #efe;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.offeringDesc {
  font-size: 1.2em;
  padding: 0 10%;
  font-weight: 500;
}
.differentiatorsCard {
  width: 254px;
  height: 200px;
  border-radius: 5px;
  font-weight: 500;
  font-size: large;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.1) inset;
}

.basicCARD {
  border-radius: 15px;
  font-size: 2.5vw !important;
  font-weight: 500 !important;
}
.basicCardText {
  padding: 0 15px;
  font-size: 0.5em !important;
}
@media screen and (max-width: 576px) {
  .basicCardText {
    padding: 0 15px;
    font-size: 0.75em !important;
  }
  .imageText{
    font-size: 0.9em !important;
  }
}
