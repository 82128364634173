/* Press Release */
section {
  position: relative;
  font-size: 10px;
}

.image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  background: rgba(35, 35, 35, 0.5);

  /* center overlay text */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  inset: 0;
}

.overlay .text {
  font-size: 3.2vw;
  font-family: "Poppins", sans-serif !important;
  color: #fff;
  font-family: inherit;
}

.pressReleaseContent {
  padding: 3em 6em;
  margin: 0 auto;
  font-family: "Poppins", sans-serif !important;
}

.pressReleaseContent .pressReleaseContent__title {
  text-align: left;
  font-size: 2.2vw;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 1.2em;
}

.pressReleaseContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.2em;
}

.pressReleaseCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  background-color: #bcf2cf66;
  border-radius: 15px;
}

.imageContainer {
  border: none;
  border-radius: 15px;
}

.imageContainer img {
  width: 21vw;
  height: 100%;
  border-radius: 15px;
}

.pressReleaseCardText {
  font-family: "Poppins", sans-serif !important;
  padding: 1em 0;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  height: 100%;
}

.pressReleaseCardText__1 {
  font-size: 1.3vw;
  text-align: left;
  line-height: 1.3em;
  font-weight: 600;
  margin-left: 0.7em;
  flex: 1;
  display: flex;
  align-items: flex-start;
}

.pressReleaseCardText__2 {
  flex: 1;
  display: flex;
  font-size: 1vw;
  align-items: flex-end;
  font-weight: 600;
  margin-left: 0.7em;
  color: gray;
}

@media screen and (max-width: 1215px) {
  .pressReleaseContainer {
    gap: 1.5em;
  }
}

@media screen and (max-width: 820px) {
  .pressReleaseCard {
    padding: 0.3em;
  }

  .pressReleaseCardText {
    padding: 0.6em 0;
  }

  .pressReleaseCardText__1 {
    font-size: 1.8;
  }
}

@media screen and (max-width: 700px) {
  .pressReleaseContent .pressReleaseContent__title {
    text-align: center;
    font-size: 2.5vw;
  }

  .pressReleaseCard {
    padding: 0.3em;
    flex-direction: column;
  }

  .imageContainer img {
    width: 100%;
  }

  .pressReleaseCardText {
    margin-top: 1em;
    padding: 0.6em 0.3em;
    width: 100%;
    gap: 2em;
  }

  .pressReleaseCardText__1 {
    font-size: 2vw;
  }

  .pressReleaseCardText__2 {
    font-size: 1.7vw;
  }
}

@media screen and (max-width: 525px) {
  .pressReleaseContent .pressReleaseContent__title {
    text-align: center;
    font-size: 4.2vw;
  }

  .pressReleaseContainer {
    grid-template-columns: fit-content(320px);
    justify-content: center;
  }

  .pressReleaseCard {
    padding: 0.3em;
    flex-direction: column;
  }

  .pressReleaseCardText {
    margin-top: 1em;
    padding: 0.6em 0.4em;
    width: 100%;
    gap: 2em;
  }

  .pressReleaseCardText__1 {
    font-size: 3.7vw;
  }

  .pressReleaseCardText__2 {
    font-size: 2.9vw;
  }
}
