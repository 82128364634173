.flex {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 25px 0;
}

.letusknowcard {
  width: 85%;
  height: 200px;
  border-radius: 10px;
  border: 3px solid #004952;
  background: #f9feff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25),
    0px 0px 8px 0px rgba(0, 0, 0, 0.25) inset;
  margin: 10px 0;
}

.cardLabel {
  font-weight: 700;
  font-family: "SF Mono", sans-serif;
  font-size: 1.7em;
  color: #335255;
}

.cardTitle {
  font-weight: 500;
  font-family: "SF Mono", sans-serif;
  color: #335255;
  font-size: 1.3rem;
}

@media screen and (min-width: 576px) {
  .letusknowcard {
    width: 45%;
  }
}

@media screen and (min-width: 768px) {
  .letusknowcard {
    width: 30%;
  }
  .cardLabel {
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    font-size: 1.7em;
    color: #335255;
  }
}

@media screen and (min-width: 1280px) {
  .letusknowcard {
    width: 25%;
  }
  .cardTitle {
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    color: #335255;
    font-size: 1.3rem;
  }
}
