.fulltext{
    text-align:left;
    padding: 15px 0;
    /* justify-content: space-evenly; */
}
.eachtext{
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 400;
    cursor: pointer;
    padding: 15px;
}
.selectedCard{
    background-color: white;
}
.bold{
    font-weight: bold;
}
