.contentStyle {
  /* margin-top :64px; */
  text-align: center;
  height: 95%;
  /* line-height: 120px; */
  color: #000000;
  background-color: #ffffff;
}

.footerStyle {
  text-align: center;
  color: #fff;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  background-color: #031c1f;
  /* background-color: rgb(107 163 181); */
  /* background-color: rgb(144, 182, 195); */
}

.layoutStyle {
  min-height: 100vh;
}

.scrollContainer {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.backToTopIcon {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  /* color: #333; */
}

:where(.backto) {
  background-color: #63005f !important;
}

.backtotop {
  display: flex !important;
  justify-content: center;
  align-items: center;
  bottom: 80px;
  height: 40px;
  width: 40px;
  background-color: #63005f !important;
}

/* .scrollContainer:before {
    content: '';
    display: block;
    height: 100vh;
    pointer-events: none;
  } */

.scrollContainer a {
  position: fixed;
  bottom: 20px;
  z-index: 11;
  border: 1px solid;
  padding: 15px;
  border-radius: 50%;
  right: 20px;
  background-color: "#63005f";
}
