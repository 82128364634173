.image {
  width: 100%;
  height: auto;
}
.imageStyle {
  position: relative;
  text-align: center;
}
.textStyle {
  font-family: "Poppins", sans-serif;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icons {
  transform: translateY(-40%);
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.icon {
  background: #004952;
  border-radius: 50%;
  width: 40px;
  padding: 2px;
}
.firstIcon {
  width: 40px;
}
.bold {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}
.solutionIcon {
  fill: #fff;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
  border: 1px solid grey;
  border-radius: 50%;
  padding: 3px;
}
.moreInfo {
  font-family: "Poppins", sans-serif;
  color: white;
  border-radius: 10px;
  background: linear-gradient(180deg, #2769f2 0%, #c703cd 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 0px 5px 0px rgba(0, 0, 0, 0.25) inset;
  display: flex;
  justify-content: center;
  align-items: center;
}
.techButton{
  font-family: "Poppins", sans-serif;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
