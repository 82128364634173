.image {
  width: 100%;
  height: auto;
}
.containerStyle {
  position: relative;
  text-align: center;
}
.textStyle {
  font-weight: 700;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2.5vw;
  font-family: 'Poppins',sans-serif;
}
.joinWithUsBtn {
  color: white;
  min-width: 15%;
  border-radius: 10px;
  font-family: 'Poppins',sans-serif;
  font-weight: 600;
  background: linear-gradient(180deg, #2769f2 0%, #c703cd 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 0px 5px 0px rgba(0, 0, 0, 0.25) inset;
}
.successCard {
  height: auto;
  border: 2px solid #004952;
  border-radius: 7px;
  background: rgba(208, 222, 223, 0.3);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25),
    0px 0px 8px 0px rgba(0, 0, 0, 0.25) inset;
}

.personName {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  padding: 2px 0;
  font-family: 'Poppins',sans-serif;
}
.successstoriesTitle {
  text-decoration: underline;
  font-size: 2em;
  font-weight: 400;
  font-family: 'Poppins',sans-serif;
}

