.pragmasoftTitle {
  text-decoration: underline;
  text-underline-offset: 10px;
  font-size: 1.5em;
  margin-top: 50px;
}
.pragmasoftCard {
  width: 300px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
.cardIcon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.allCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.image {
  width: 100%;
  height: auto;
}
.imageStyle {
  position: relative;
  text-align: center;
}
.textStyle {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.weDealWithCard {
  width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
.weDealWithIcon {
  min-width: 75px;
  height: 100px;
  padding: 5px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
}
@media screen and (max-width: 768px) {
  .weDealWithCard {
    width: 300px;
  }
}