.image {
  width: 100%;
  height: auto;
}
.totalContent {
  margin: 0 4% 4% 4%;
  text-align: left;
  font-family: 'Poppins',sans-serif;
  font-weight: 400;
}
.imageStyle {
  position: relative;
  text-align: center;
}
.textStyle {
  font-weight: 700;
  font-family:'Poppins',sans-serif;
  background: rgba(0, 0, 0, 0.60);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
