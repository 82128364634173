.movingText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  width: 250px;
  height: 70px;
  background-color: white;
  position: absolute;
  left: -300px;
  transform: translate(0, -50%);
  transition: left 500ms ease-in-out;
  z-index: 1;
  border-top-right-radius: 50px 50px;
  border-bottom-right-radius: 50px 50px;
}

.movingTextTitle {
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}
.movingTextLabel {
  font-family: "Poppins", sans-serif;
  text-align: right !important;
  padding: 0 20px;
}

/* @media screen and (max-width: 1280px) {
  .movingText {
    top: 75%;
  }
} */

@media screen and (max-width: 992px) {
  .movingText {
    top: 14%;
    width: 400px;
    height: 90px;
  }

  .movingTextTitle {
    font-size: 19px;
  }

  .movingTextLabel {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .movingText {
    top: 6.5%;
    width: 300px;
    height: 70px;
  }

  .movingTextTitle {
    font-size: 15px;
  }

  .movingTextLabel {
    font-size: 12px;
  }
}

@media screen and (max-width: 648px) {
  .movingText {
    top: 6%;
    width: 300px;
    height: 70px;
  }
}

@media screen and (max-width: 576px) {
  .movingText {
    top: 5.7%;
  }
}

@media screen and (max-width: 467px) {
  .movingText {
    top: 5.3%;
  }
}

/* @media screen and (max-width: 576px) {
  .movingText {
    top: 40%;
  }
}

@media screen and (min-width: 576px) {
  .movingText {
    top: 50%;
  }
}
@media screen and (min-width: 768px) {
  .movingText {
    top: 75%;
    width: 300px;
    height: 70px;
  }
}

@media screen and (min-width: 992px) {
  .movingText {
    top: 75%;
    width: 300px;
    height: 70px;
  }
}
@media screen and (min-width: 1280px) {
  .movingText {
    top: 75%;
  }
} */
