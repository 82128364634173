/* IT Staff Agency */
.itWrapper {
  position: relative;
  font-size: 10px;
}

.image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  background: rgba(37, 37, 37, 0.5);
  height: 100%;
  /* center overlay text */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  inset: 0;
}

.overlay .text_1 {
  font-size: 3.3vw;
  font-family: "Poppins", sans-serif !important;
  color: #fff;
}

.overlay .text_2 {
  font-size: 2.6vw;
  font-family: "Poppins", sans-serif !important;
  color: #fff;
}

.scrollToExplore {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.strengths {
  padding: 30px;
}

.itTexts {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 1.2em;
}

.itTexts_1 {
  border-bottom: 1px solid black;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 2.6em;
}

.itTexts_2 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.8em;
}

.itTexts_3 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.3em;
  padding-top: -1px;
}

.itTexts_2_1 {
  font-family: "Poppins", sans-serif;
  display: block;
  font-weight: 500 !important;
  font-size: 2em;
}

.hiring_card {
  height: 100%;
  border-radius: 0;
  padding: 35px;
}

.mobile {
  border-radius: 10px;
}

.card_title {
  font-family: "Poppins", sans-serif;
  font-size: 1.5em;
  font-weight: 400;
}

.card_text {
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 300;
}

.card_image {
  width: 155px;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1200px) {
  .itTexts_1 {
    font-size: 2.2em;
  }

  .itTexts_2 {
    font-size: 1.4em;
  }

  .itTexts_3 {
    font-size: 1.2em;
    padding-top: -1px;
  }

  .itTexts_2_1 {
    font-size: 1.9em;
  }

  .card_title {
    font-size: 1.3em;
  }

  .card_text {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 992px) {
  .strengths {
    padding: 15px 30px;
  }

  .itTexts {
    margin-bottom: 0;
  }

  .itTexts_1 {
    font-size: 2em;
  }

  .itTexts_2 {
    font-size: 1.3em;
  }

  .itTexts_3 {
    font-size: 1.1em;
    padding-top: -1px;
  }

  .itTexts_2_1 {
    font-size: 1.8em;
  }

  .card_title {
    font-size: 1.2em;
  }

  .card_text {
    font-size: 0.7em;
  }
}

@media screen and (max-width: 768px) {
  .strengths {
    padding: 15px 30px;
  }

  .itTexts {
    margin-bottom: 1em;
  }

  .itTexts_1 {
    font-size: 2em;
  }

  .itTexts_2 {
    width: 80%;
    font-size: 1.2em;
  }

  .image_card {
    margin: 0 auto;
    width: 80% !important;
  }

  .itTexts_3 {
    font-size: 1em;
    padding-top: -1px;
  }

  .itTexts_2_1 {
    font-size: 1.6em;
  }

  .card_image {
    display: none;
  }

  .card_title {
    font-size: 1.4em;
  }

  .card_text {
    font-size: 1em;
  }
}

@media screen and (max-width: 576px) {
  .itTexts {
    margin-bottom: 0.3em;
  }

  .itTexts_1 {
    font-size: 1.8em;
  }

  .itTexts_2 {
    width: 80%;
    font-size: 1em;
  }

  .itTexts_3 {
    font-size: 0.8em;
  }

  .itTexts_2_1 {
    font-size: 1.4em;
  }

  .card_title {
    font-size: 1.2em;
  }

  .card_text {
    font-size: 0.9em;
  }
}
