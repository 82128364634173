.footer_column_1 {
  font-weight: 800;
  font-size: 1.6em;
  color: #cdcdcd;
  display: flex;
  align-items: center;
}

.footer_column_2 {
  text-align: end;
}

.phoneEmail {
  display: flex;
  justify-content: flex-end;
}

.email,
.phone {
  display: flex;
  font-family: "Poppins", sans-serif;
  gap: 8px;
  align-items: center;
  margin-right: 20px;
}

.emailImage {
  font-size: 1.7em;
  margin-right: 0.3em;
}

.phoneImage {
  font-weight: 800;
  font-size: 1.2em;
  color: #fff;
  margin-right: 0.3em;
}

.antDivider,
.antDivider2 {
  border-bottom: 1px solid #fff;
}

.footer_row_2 {
  display: flex;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  justify-content: center;
  font-size: 1.2em;
}

.subFooterHeading {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.4em;
  padding-bottom: 0.6em;
  border-bottom: 0.1em solid #cdcdcd;
}

.details_space {
  text-align: left;
  width: 18em;
}

.listName {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 1.2em;
}

.listName:not(:last-child) {
  margin-bottom: none;
}

.logoTextContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  align-items: center;
}

.logoContainer {
  align-items: center;
  width: 4em;
}

.socials {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.socials2 {
  display: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  font-size: 1.1em;
  font-weight: 500;
}

.footer_bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer_md {
  font-family: "Poppins", sans-serif;
  display: none;
}

.footer_bottom__copyright {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 1.2em;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.footer_bottom_pts {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  gap: 0.5em;
  font-size: 1.2em;
  font-weight: 500;
}

.socials2 {
  display: none;
}

@media screen and (max-width: 1132px) {
  .details_space {
    width: 14em;
  }
}

@media screen and (max-width: 970px) {
  .details_space {
    width: 12em;
  }

  .socials {
    gap: 0.6em;
  }
  .socials2 {
    display: none;
  }
}

@media screen and (max-width: 805px) {
  .details_space {
    width: 10em;
  }

  .phoneEmail {
    justify-content: center;
    font-size: 1em;
  }

  .footer_row_2 {
    font-size: 1.3em;
  }
  .footer_lg {
    display: none;
  }

  .footer_md {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer_md div > * {
    color: #fff;
  }

  .footer_md div {
    display: flex;
    gap: 1.5em;
  }

  .footer_md div:not(:last-child) {
    margin-right: 1.5em;
  }

  .footer_bottom_pts {
    display: none;
  }

  .socials2 {
    display: initial;
    gap: 10px;
  }

  .socials2 a {
    margin-right: 10px;
  }
}

@media screen and (max-width: 768px) {
  .phoneEmail {
    margin-top: 1.5em;
  }

  .footer_bottom__copyright {
    font-size: 1em;
  }
}

@media screen and (max-width: 576px) {
  .details_space {
    width: 12em;
  }

  .phoneEmail {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }

  .email {
    margin-right: 5px;
  }

  .socials2 a {
    margin-right: 5px;
  }
}
