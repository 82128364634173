.title{
    font-family: 'Poppins',sans-serif;
    font-size:1.5em ;
    text-decoration: underline;
}
.font{
    font-size: 1.3em;
}
.text{
    font-family: 'Poppins',sans-serif;
    font-size:1.2em ;
    font-weight: 500;
}