.image {
  width: 100%;
  height: auto;
}
.imageStyle, .containerStyle {
  position: relative;
  text-align: center;
}
.textStyle {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
}
.icons {
  transform: translateY(-15%);
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.icon {
  width: 50px;
}
.bold, .card, .letUsknowcard, .textStyle  {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.desc {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.card, .letUsknowcard {
  fill: #f9feff;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25) inset;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  color: #335255 !important;
  font-size: 1.1em;
}
.card{
  width: 100%;
  height: 300px;
}
.letUsknowcard {
  width: 250px;
  height: 200px;
}
.power,.label{
  font-weight: 700;
  font-size: 1.4em;
}
.title{
  font-size: 1.2em!important;
}
/* .iconContainer {
  position: relative;
} */

/* .iconText {
  font-family: 'Poppins',sans-serif;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  text-align: center;
  width: 100%;
  color: red;
  font-weight: bolder;
  font-size: 0.5em;
  display: none;
  transition: opacity 0.3s ease-in-out;
} */
.getStartedBtn{
  min-width: 12%;
    font-family: "Poppins", sans-serif;
    color: white;
    border-radius: 10px;
    background: linear-gradient(180deg, #2769f2 0%, #c703cd 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
      0px 0px 5px 0px rgba(0, 0, 0, 0.25) inset;
  }


@media (max-width:992px) and  (min-width:768px ) {
  .letUsknowcard {
    width: 150px;
    height: 160px;
  }
  .power,.label{
    font-weight: 700;
    font-size: 1em;
  }
  .title{
    font-size: 0.75em!important;
  }
}

@media only screen and (max-width: 768px) {
  /* .tooltip {
    display: none;
  } */
  .getStartedBtn{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .letUsknowcard {
    display: none;
  }
  /* .iconContainer:hover .iconText {
    display: block;
  }
  .iconContainer:hover{
    transform: scale(1.5);
    cursor: pointer;
    opacity: 0.75;
  } */
  .desc{
    display: none;
  }
}

