.contactWrapper {
  font-size: 10px;
  padding: 48px 0;
  overflow-x: hidden;
}

/* .applybutton {
  border-radius: 10px;
  background: linear-gradient(180deg, #2769f2 0%, #c703cd 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 0px 5px 0px rgba(0, 0, 0, 0.25) inset;
  color: white;
  min-width: 20%;
  display: block;
  font-weight: bold;
} */

.btn {
  display: inline-flex;
  padding: 24px 48px;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 10px;
  background: linear-gradient(180deg, #2769f2 0%, #c703cd 100%);
  font-size: 22px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 0px 5px 0px rgba(0, 0, 0, 0.25) inset;
  color: #fff;
}

.formText {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.poppins_agile {
  font-family: "Poppins", sans-serif;
  font-size: 3em;
  font-weight: 275;
  margin-bottom: -0.1em;
}
.experience {
  font-family: "Poppins", sans-serif;
  font-size: 2.3em;
  font-weight: 500;
  margin-bottom: -6px;
}
.connect {
  font-family: "Poppins", sans-serif;
  font-size: 2em;
  font-weight: 400;
  margin-top: 0;
}
.contact {
  font-family: "Poppins", sans-serif;
  font-size: 1.4em;
  font-style: normal;
  font-weight: 500;
  margin-bottom: -0.4em;
}

.formInput {
  border: 0.5px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  padding: 0.8em 1em;
  font-family: "Poppins", sans-serif;
}

.formInput::placeholder {
  color: #333;
  font-size: 1.1em;
}

@media screen and (max-width: 1200px) {
  .poppins_agile {
    font-size: 2.8em;
  }

  .experience {
    font-size: 2.1em;
  }

  .connect {
    font-size: 1.8em;
  }

  .contact {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 992px) {
  .contactWrapper {
    padding: 48px 30px;
  }

  .poppins_agile {
    font-size: 2.6em;
  }

  .experience {
    font-size: 1.9em;
  }

  .connect {
    font-size: 1.6em;
  }

  .contact {
    font-size: 1em;
  }
}

@media screen and (max-width: 768px) {
  .contactWrapper {
    padding: 48px 30px;
  }

  .formText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.7em;
  }

  .poppins_agile {
    font-size: 2.8em;
  }

  .experience {
    font-size: 2.1em;
  }

  .connect {
    font-size: 1.8em;
  }

  .contact {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 576px) {
  .contactWrapper {
    padding: 48px 30px;
  }

  .poppins_agile {
    font-size: 2.6em;
  }

  .experience {
    font-size: 1.9em;
  }

  .connect {
    font-size: 1.6em;
  }

  .contact {
    font-size: 1em;
  }
}

@media screen and (max-width: 530px) {
  .poppins_agile {
    font-size: 2.2em;
  }

  .experience {
    font-size: 1.5em;
  }

  .connect {
    font-size: 1.2em;
  }

  .contact {
    font-size: 0.6em;
  }
}

@media screen and (max-width: 505px) {
  .poppins_agile {
    font-size: 2em;
  }
}
