.LogoAlignment {
  width: 14em;
  margin-right: 9em;
  font-family: 'Poppins',sans-serif;
}
.logoStyle{
  display: flex;
  font-family: 'Poppins',sans-serif;
}
.menuStyle{
  flex: 1;
  min-width: 0;
  font-size:large;
  font-family: 'Poppins',sans-serif;
  font-weight: 500;
  color: #031C1F;
}

@media only screen and (max-width: 600px) {
  .LogoAlignment {
      width: 10em;
      margin-right: 6em;
  }
}

@media only screen and (min-width: 768px) {
  .menuStyle{
    flex: 1;
    min-width: 0;
    font-size:larger;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 40%;
    left: 30%;
  }
}