.bannerImage {
  position: relative;
  font-size: 10px;
}

.image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  background: rgba(50, 50, 50, 0.5);

  /* center overlay text */
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
}

.overlay .text {
  font-size: 4vw;
  font-family: "Poppins", sans-serif !important;
  color: #fff;
  font-family: inherit;
}

.blog_card_contatiner {
  padding: 45px 0 45px 45px;
}

.rightSection {
  display: flex;
  flex-direction: column;
  gap: 1.2em;
}

.services,
.industries,
.allBlogs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1vw;
  font-weight: 600;
  padding: 1.3em 1.4em 1.3em 1.4em;
  background-color: #bcf2cf66;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.blog_card {
  border-radius: 15px;
  border: 0.2em dotted rgb(224, 223, 223);
}

.blog_card img {
  padding: 1.2em 1.2em 0 1.2em;
  height: auto;
}

.blog_texts {
  padding: 0.6em 1.5em;
  text-align: left;
}

.blog_texts .title {
  color: #005cc9;
  font-family: "Poppins", sans-serif !important;
  font-size: 1.5vw;
  line-height: 1.3em;
  font-weight: 600 !important;
}

.blog_texts .description {
  font-size: 1.2vw;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
  line-height: 1.3em;
}

.date_author {
  margin-top: 0.7em;
}

.date_author .date {
  display: block;
}

.date_author .date,
.date_author .author {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  font-size: 1vw;
  line-height: 1.4em;
  color: rgba(19, 19, 19, 0.6);
}

@media screen and (max-width: 1200px) {
  .blog_texts {
    padding: 0.6em;
  }
}

@media screen and (max-width: 992px) {
  .blog_texts {
    padding: 0.6em 0.8em;
  }

  .blog_texts .title {
    font-size: 1.5vw;
    line-height: 1.3em;
  }

  .blog_texts .description {
    font-size: 1.2vw;
    line-height: 1em;
  }

  .date_author {
    margin-top: 1.3em;
  }

  .date_author .date,
  .date_author .author {
    font-weight: 600;
    font-size: 1vw;
    line-height: 0.3em;
  }

  .services,
  .industries,
  .allBlogs {
    font-size: 0.7em;
  }
}

@media screen and (max-width: 768px) {
  .blog_texts {
    padding: 0.6em 0.8em;
  }

  .blog_texts .title {
    /* font-size: 1.6vw; */
    font-size: 0.8em;
    line-height: 1.3em;
  }

  .blog_texts .description {
    /* font-size: 1.4vw; */
    font-size: 0.7em;
    line-height: 0.3em !important;
  }

  .date_author {
    margin-top: 1.3em;
  }

  .date_author .date,
  .date_author .author {
    font-weight: 600;
    /* font-size: 1.2vw; */
    font-size: 0.6em;
    line-height: 0.3em;
  }

  .services,
  .industries,
  .allBlogs {
    font-size: 0.7em;
  }
}

@media screen and (max-width: 576px) {
  .blog_card_contatiner {
    padding: 40px;
  }

  .blogDetailsColumn {
    margin-bottom: 1.5em;
  }

  .blog_texts {
    padding: 0.6em 0.8em;
  }

  .blog_texts .title {
    font-size: 1.2em;
    line-height: 1.3em;
  }

  .blog_texts .description {
    font-size: 1em;
    line-height: 0.3em !important;
  }

  .date_author {
    margin-top: 1.3em;
  }

  .date_author .date,
  .date_author .author {
    font-weight: 600;
    font-size: 0.9em;
    line-height: 1em;
  }

  .services,
  .industries,
  .allBlogs {
    font-size: 1em;
  }
}
