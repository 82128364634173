.contentAlendment {
  min-height: 80vh;
  height: 100%;
  /* background: cadetblue; */
  padding: 0 2rem;
  font-family: "Poppins", sans-serif;
}

.bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 101%;
  width: 101%;
  z-index: 0;
  overflow: hidden;
}

.switchCard {
  border-radius: 5px;
  background: var(--light-gree, #e0fbff);
  border: none;
  color: #000;
  cursor: pointer;
  pointer-events: none;
}

.titleCssTextwrapper {
  height: 60px;
  /* width: 497px; */
  color: var(--dark-green);
  font-family: "Poppins", sans-serif;
  font-size: 2em;
  font-weight: 400;
  /* left: 0; */
  letter-spacing: 0;
  line-height: normal;
  /* position: fixed; */
  /* top: 0; */
}

.labelCssTextwrapper {
  color: var(--dark-green, #031c1f);
  font-family: "Poppins", sans-serif;
  font-size: 1.8em;
  font-style: normal;
  font-weight: 275;
  line-height: normal;
  text-align: right;
}

.letUsKnowTitle {
  text-decoration: underline;
  text-underline-offset: 12px;
}

.letUsKnowTitle::first-letter {
  text-decoration: underline;
  text-underline-offset: 12px;
  text-decoration-color: var(--dark-green, #031c1f);
}

.title {
  font-family: "Poppins", sans-serif;
  font-weight: 300 !important;
  font-size: 2.6vw !important;
  padding-bottom: 7px;
  border-bottom: 1.5px solid black;
}

.text {
  color: #000;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  padding-bottom: 0 !important;
}

.ceo {
  font-family: inherit;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
}

.underline {
  border-bottom: 2px solid black !important;
  padding-bottom: 5px;
  display: inline-block;
}
.fw {
  font-weight: 300;
  font-size: 2em;
}
.dataimagestyle {
  width: 70% !important;
}
.applybutton {
  border-radius: 10px !important;
  background: linear-gradient(180deg, #2769f2 0%, #c703cd 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 0px 5px 0px rgba(0, 0, 0, 0.25) inset;
  color: white;
  min-width: 30%;
}

.btn {
  display: inline-flex;
  padding: 20px 45px !important;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 10px;
  background: linear-gradient(180deg, #2769f2 0%, #c703cd 100%);
  font-size: 1.2vw;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 0px 5px 0px rgba(0, 0, 0, 0.25) inset;
  color: #fff;
}
.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

@media screen and (max-width: 1200px) {
  .btn {
    font-size: 1.3vw;
  }

  .text {
    line-height: 130%;
  }
}

@media screen and (max-width: 992px) {
  .title {
    font-size: 3vw !important;
  }

  .btn {
    padding: 16px 40px !important;
    font-size: 1.5vw;
  }

  .text {
    line-height: 125%;
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 3.5vw !important;
  }

  .ceo {
    font-size: 2.5vw;
  }

  .text {
    font-size: 2.5vw;
    line-height: 140%;
  }

  .btn {
    padding: 22px 45px !important;
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 576px) {
  .title {
    font-size: 3.7vw !important;
  }

  .text {
    font-size: 2.7vw;
  }

  .ceo {
    font-size: 2.7vw;
  }

  .btn {
    padding: 18px 45px !important;
    font-size: 3.2vw;
  }
}
