.headerStyle {
  display: flex;
  align-items: center;
  padding: 0 25px;
  z-index: 5;
  position: relative;
  font-family: 'Poppins',sans-serif;
  background-color: white;
}

@media only screen and (max-width: 768px) {
  .headerStyle {
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
}