/* Support */
section {
  position: relative;
  font-size: 10px;
}

.contentAlendment {
  min-height: 80vh;
  height: 100%;
  /* background: cadetblue; */
  padding: 0 2rem;
  font-family: "SF Mono", sans-serif;
}

.image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  background: rgba(57, 57, 57, 0.5);

  /* center overlay text */
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
}

.overlay .text {
  font-size: 6vw;
  font-family: "SF Mono", sans-serif !important;
  color: #fff;
  font-family: inherit;
}

.supportText {
  padding: 3em 6em;
  width: 100vw;
  font-family: "SF Mono", sans-serif !important;
  display: flex;
  text-align: left;
  flex-direction: column;
}

.supportText .supportText_title {
  /* font-size: 2.3em; */
  font-size: 2.3vw;
  font-weight: 600;
  line-height: 1.5;
  /* padding: 0.3em; */
}

.supportText ul {
  padding-left: 1.7em;
  padding-top: 0;
  font-size: 1.7vw;
  font-family: "SF Mono", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-bottom: 1.7em;
}

.description {
  padding-top: 0.7em;
  font-family: "SF Mono", sans-serif;
  font-size: 1.7vw;
  margin-bottom: 1.7em;
}
