/* Support */
.section_1 {
  position: relative;
  font-size: 10px;
}

.image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  background: rgba(57, 57, 57, 0.5);

  /* center overlay text */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 1.5em;
  inset: 0;
}

.overlay .text {
  font-size: 3.3vw;
  font-family: "SF Mono", sans-serif !important;
  color: #fff;
  font-family: inherit;
}

.btn {
  display: inline-flex;
  padding: 23px 32px;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 600;
  background: linear-gradient(180deg, #2769f2 0%, #c703cd 100%);
  font-size: 1.5vw;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 0px 5px 0px rgba(0, 0, 0, 0.25) inset;
  color: #fff;
}

/* Section 2 */
.sectionDetails {
  padding: 3em !important;
}

.color_1 {
  background-color: #d0dedf;
}

.color_2 {
  background-color: #fff;
}

.columnDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}

.heading {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
}

.headingText {
  border-bottom: 2px solid black;
  font-size: 2.3vw;
}

.ourTeamContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25vw;
}

.ourTeamImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  margin-top: 0.3em;
  font-size: 1.9vw !important;
}

.about {
  /* margin-top: 0; */
  line-height: 1.2em;
  font-size: 1.4vw;
}

.imageTextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 992px) {
  .overlay .text {
    font-size: 3.4vw;
  }

  .btn {
    display: inline-flex;
    padding: 21px 30px;
    justify-content: center;
    align-items: center;
    font-size: 1.6vw;
    border: none;
    font-weight: 600;
  }

  .headingText {
    font-size: 3vw;
  }

  .title {
    font-size: 2.1vw !important;
  }

  .about {
    font-size: 1.6vw;
  }
}

@media screen and (max-width: 768px) {
  .overlay .text {
    font-size: 3.4vw;
  }

  .btn {
    padding: 20px 29px;
    font-size: 1.7vw;
  }

  .headingText {
    font-size: 3.2vw;
  }

  .title {
    font-size: 2.3vw !important;
  }

  .about {
    font-size: 1.7vw;
  }
}

@media screen and (max-width: 576px) {
  .overlay .text {
    font-size: 3.8vw;
  }

  .btn {
    padding: 16px 25px;
    font-size: 1.8vw;
  }

  .headingText {
    font-size: 3.4vw;
  }

  .title {
    font-size: 3vw !important;
  }

  .about {
    font-size: 2.3vw;
  }
}
