.insights {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.insightsText {
  font-family: "Poppins", sans-serif;
  color: #005cc9;
  text-align: center;
  font-size: 0.9em;
  font-style: normal;
  font-weight: 500;
}
.insightsMidtext {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1em;
}
.viewArticle {
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
  background: #d4ffea;
}
