.skillIcon {
  font-family: 'Poppins',sans-serif;
  min-width: 50px;
  height: 50px;
  padding: 5px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
}
.allSkillIcons,
.mobileSkillIcons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.mobileSkillIcons {
  flex-wrap: wrap;
}
