.letUsKnowContainer {
  padding: 0 10px;
  font-size: 10px;
  margin-top: 10px;
}

.letUsKnowTitle {
  font-family: "Poppins", sans-serif;
  margin: 0 auto;
  border-bottom: 1.5px solid black;
  width: fit-content;
  font-weight: 275 !important;
  font-size: 2.6vw;
  margin-bottom: 0.5em;
}

.letusKnowContent {
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 1.6vw;
  line-height: 1.6em;
  margin-bottom: 1em;
}

.team {
  position: relative;
  text-align: center;
  font-size: 30px;
}

.team::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 0;
}

.team_img {
  max-width: 100%;
  min-height: 25vh;
}

.team_info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bolder;
  font-family: "Poppins", sans-serif;
  margin: 0;
}

.team_info .team_header {
  font-family: inherit;
  font-size: 3vw;
  font-weight: 400;
  margin-bottom: 2em;
}

.team_info .team_data {
  font-size: 2.5vw;
  font-weight: 600;
  line-height: 1.3em;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.5em;
  flex-wrap: wrap;
}

.letusknowcard {
  width: 28%;
  height: 17em;
  padding: 4em 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  border-radius: 10px;
  border: 3px solid #004952;
  background: #f9feff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25),
    0px 0px 8px 0px rgba(0, 0, 0, 0.25) inset;
  margin: 10px 0;
}

.cardLabel {
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  font-size: 48px;
  color: #335255;
}

.cardTitle {
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #335255;
  line-height: 0.9em;
  font-size: 32px;
}

@media screen and (max-width: 1280px) {
  .letusknowcard {
    width: 27%;
  }

  .letUsKnowTitle {
    font-size: 3.5vw;
  }

  .letusKnowContent {
    font-size: 2vw;
  }

  .team_info .team_header {
    font-size: 3vw;
  }

  .team_info .team_data {
    font-size: 2.5vw;
    line-height: 1.1em;
  }

  .cardLabel {
    font-size: 45px;
  }

  .cardTitle {
    font-size: 29px;
  }
}

@media screen and (max-width: 992px) {
  .letusknowcard {
    width: 27%;
  }

  .letUsKnowTitle {
    font-size: 3.3vw;
  }

  .letusKnowContent {
    font-size: 2.2vw;
  }

  .team_info .team_header {
    font-size: 3.5vw;
  }

  .team_info .team_data {
    font-size: 2.8vw;
    line-height: 1px;
  }

  .cardLabel {
    font-size: 40px;
  }

  .cardTitle {
    font-size: 23px;
  }
}

@media screen and (max-width: 768px) {
  .flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    flex-wrap: wrap;
  }

  .letUsKnowTitle {
    font-size: 4.2vw;
  }

  .letusKnowContent {
    font-size: 2.8vw;
  }

  .team_info .team_header {
    font-size: 4.5vw;
  }

  .team_info .team_data {
    font-size: 3vw;
    line-height: 0.7em;
  }

  .letusknowcard {
    width: 42%;
  }

  .cardLabel {
    font-size: 40px;
  }

  .cardTitle {
    font-size: 21px;
  }

  .team_info {
    font-size: 22px;
  }
}

@media screen and (max-width: 576px) {
  .letUsKnowTitle {
    font-size: 6vw;
  }

  .letusKnowContent {
    font-size: 4vw;
  }

  .letusknowcard {
    width: 75%;
  }

  .team_info .team_header {
    font-size: 5vw;
  }

  .team_info .team_data {
    font-size: 3vw;
    line-height: 0.7em;
  }

  .cardLabel {
    font-size: 48px;
  }

  .cardTitle {
    font-size: 32px;
  }
}
