.testimonialsTextWrapper {
  display: flex;
  flex-direction: column;
  padding-top: 1.5em;
}

.testimonialsTitle {
  display: inline-block;
  justify-content: center;
  align-items: center;
  font-size: 2.8em;
  font-family: "Poppins", sans-serif;
  border-bottom: 1px solid black;
  margin-bottom: 0.5em;
}

.testimonialsAbout {
  font-size: 2em;
  font-weight: 400;
}

.successCard {
  height: auto;
  border: 2px solid #004952;
  border-radius: 7px;
  background: rgba(208, 222, 223, 0.3);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25),
    0px 0px 8px 0px rgba(0, 0, 0, 0.25) inset;
}

.person {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
}

.person_name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.person_profession {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.person_about {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  padding: 15px;
}

@media screen and (max-width: 1200px) {
  .testimonialsTitle {
    font-size: 2.6em;
  }

  .testimonialsAbout {
    font-size: 1.8em;
  }

  .person_name {
    font-size: 14px;
  }

  .person_profession {
    font-size: 12px;
  }
  .person_about {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    padding: 15px;
  }
}

@media screen and (max-width: 992px) {
  .testimonialsTitle {
    font-size: 2.3em;
  }

  .testimonialsAbout {
    font-size: 1.6em;
  }

  .person_name {
    font-size: 14px;
  }

  .person_profession {
    font-size: 12px;
  }

  .person_about {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .testimonialsTitle {
    font-size: 2em;
  }

  .testimonialsAbout {
    font-size: 1.3em;
  }

  .person_name {
    font-size: 12px;
  }

  .person_profession {
    font-size: 10px;
  }

  .person_about {
    font-size: 14px;
  }
}

@media screen and (max-width: 576px) {
  .testimonialsTitle {
    font-size: 1.8em;
  }

  .testimonialsAbout {
    font-size: 1.2em;
  }

  .person_name {
    font-size: 14px;
  }

  .person_profession {
    font-size: 12px;
  }

  .person_about {
    font-size: 16px;
  }
}
