.aboutus_wrapper {
  font-size: 10px;
}

.aboutus_title {
  font-family: "Poppins", sans-serif !important;
  font-size: 2.7em !important;
  font-weight: 300 !important;
}

.aboutus_paragraph {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  line-height: 1.3em !important;
  font-size: 1.6em;
}

.aboutus_score {
  font-family: "Poppins", sans-serif;
  font-size: 2em;
  font-weight: 700;
  color: #335255;
}

.aboutus_descriptionWrap {
  margin: 0 auto;
  width: 70%;
}

.aboutus_description {
  font-family: "Poppins", sans-serif;
  font-size: 1.4em;
  font-weight: 500;
  color: #335255;
}

/* About us banner */
.aboutUsBanner {
  position: relative;
  font-size: 10px;
}

.image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  background: rgba(217, 217, 217, 0.5);

  /* center overlay text */
  display: flex;
  flex-direction: column;
  gap: 0.6em;
  align-items: center;
  justify-content: center;
  inset: 0;
  padding: 0 2em;
}

.overlay .text_1 {
  font-size: 3.1em !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 300;
}

.overlay .text_2 {
  font-size: 2.2em !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  line-height: 1.2em;
  padding: 0 7em;
}

.divider {
  position: relative;
}

.line {
  width: 70%;
  height: 3px;
  background-color: #335255;
}

.caretIcon {
  position: absolute;
  color: #335255;
  font-size: 1.7em;
  top: -0.45em;
  right: 11%;
}

/* CEO */
.dataimagestyle {
  width: 80% !important;
}

.title {
  font-family: "Poppins", sans-serif;
  font-weight: 300 !important;
  font-size: 2.6vw !important;
  padding-bottom: 7px;
  border-bottom: 1.5px solid black;
}

.text {
  color: #000;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  padding-bottom: 0 !important;
}

.ceo {
  font-family: inherit;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
}

.btn {
  display: inline-flex;
  padding: 20px 45px !important;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 10px;
  background: linear-gradient(180deg, #2769f2 0%, #c703cd 100%);
  font-size: 1.2vw;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 0px 5px 0px rgba(0, 0, 0, 0.25) inset;
  color: #fff;
}

.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
}

@media screen and (max-width: 1200px) {
  .overlay .text_1 {
    font-size: 3em !important;
  }

  .overlay .text_2 {
    font-size: 2.1em !important;
    padding: 0 7em;
  }

  .btn {
    font-size: 1.3vw;
  }

  .text {
    line-height: 130%;
  }
}

@media screen and (max-width: 992px) {
  .aboutus_title {
    font-size: 2.4em !important;
  }

  .aboutus_paragraph {
    line-height: 1.3em !important;
    font-size: 1.3em;
  }

  .overlay .text_1 {
    font-size: 2.7em !important;
  }

  .overlay .text_2 {
    font-size: 1.7em !important;
    padding: 0 4em;
  }

  .aboutus_score {
    font-size: 1.7em;
  }

  .aboutus_description {
    font-size: 1.2em;
  }

  .caretIcon {
    top: -0.45em;
    right: 9%;
  }

  .title {
    font-size: 3vw !important;
  }

  .btn {
    padding: 16px 40px !important;
    font-size: 1.5vw;
  }

  .text {
    line-height: 125%;
  }
}

@media screen and (max-width: 768px) {
  .aboutus_title {
    font-size: 2.3em !important;
  }

  .aboutus_paragraph {
    line-height: 1.2em !important;
    font-size: 1.2em;
  }

  .aboutus_descriptionWrap {
    width: 80%;
  }

  .line {
    width: 60%;
  }

  .caretIcon {
    right: 18%;
  }

  .aboutus_score {
    font-size: 1.8em;
  }

  .aboutus_description {
    font-size: 1.4em;
  }

  .overlay .text_1 {
    font-size: 2.3em !important;
  }

  .overlay .text_2 {
    font-size: 1.3em !important;
    padding: 0 2em;
  }

  .title {
    font-size: 3.5vw !important;
  }

  .ceo {
    font-size: 2.5vw;
  }

  .text {
    font-size: 2.5vw;
    line-height: 140%;
  }

  .btn {
    padding: 22px 45px !important;
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 576px) {
  .aboutus_title {
    font-size: 2.1em !important;
  }

  .aboutus_paragraph {
    line-height: 1.2em !important;
    font-size: 1.1em;
  }

  .aboutus_descriptionWrap {
    width: 62%;
  }

  .line {
    width: 60%;
  }

  .caretIcon {
    right: 17%;
  }

  .aboutus_score {
    font-size: 1.8em;
  }

  .aboutus_description {
    font-size: 1.4em;
  }

  .overlay .text_1 {
    font-size: 1.8em !important;
  }

  .overlay .text_2 {
    font-size: 1em !important;
    padding: 0 1em;
  }

  .aboutUsDetailsCard {
    margin-bottom: 2em !important;
  }

  .title {
    font-size: 4vw !important;
  }

  .text {
    font-size: 2.7vw;
  }

  .ceo {
    font-size: 2.7vw;
  }

  .btn {
    padding: 18px 45px !important;
    font-size: 3.2vw;
  }
}
