.careersTitle {
  text-decoration: underline;
  text-underline-offset: 10px;
  font-size: 1.5em;
}
.image {
  width: 100%;
  height: auto;
}
.imageStyle {
  position: relative;
  text-align: center;
}
.textStyle {
  font-weight: 700;
  font-family: "SF Mono", sans-serif;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.5em;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Joinwithus {
  border-radius: 10px;
  background: linear-gradient(180deg, #2769f2 0%, #c703cd 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 0px 5px 0px rgba(0, 0, 0, 0.25) inset;
  color: white;
  min-width: 15%;
  display: block;
  font-weight: bold;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.applyStyle{
  width: 100%;
}
.submitbtn{
  min-width: 15%;
  margin-bottom: 40px;
  color: white;
}

.applyStyle, .submitbtn{
    border: none;
    position: relative;
    z-index: 1;
    border-radius: 5px;
    background: linear-gradient(to bottom, #2A60E3, #DC1FFF);
    font-weight: 500;
  }
  .applyStyle::before {
    content: "";
    position: absolute;
    left: 1px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    border-radius: 4px;
    background-color: white;
    z-index: -1;
    transition: 200ms
  }
  .applyStyle::after {
    content: attr(data);
    font-size: 16px;
    background: linear-gradient(to bottom, #2A60E3, #DC1FFF);
    color: transparent;
    transition: 200ms
  }
