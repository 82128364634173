.drivenByDataTitle {
  font-family: "Poppins", sans-serif;
  font-weight: 300 !important;
  font-size: 2.6vw !important;
  padding-bottom: 7px;
  border-bottom: 1.5px solid black;
}

.bg_color_1 {
  background-color: #d0dedf66;
}

.bg_color_2 {
  background-color: #ffffff;
}

.drivenByDataText {
  justify-self: center;
}

.drivenByDataText p {
  color: #000;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  padding-bottom: 0 !important;
}

.coloredLetter {
  color: #8b008f;
}

.btn {
  display: inline-flex;
  padding: 20px 45px !important;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 10px;
  background: linear-gradient(180deg, #2769f2 0%, #c703cd 100%);
  font-size: 1.2vw;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 0px 5px 0px rgba(0, 0, 0, 0.25) inset;
  color: #fff;
}

@media screen and (max-width: 1200px) {
  .btn {
    font-size: 1.3vw;
  }

  .drivenByDataText p {
    line-height: 130%;
  }
}

@media screen and (max-width: 992px) {
  .drivenByDataTitle {
    font-size: 3vw !important;
  }

  .btn {
    padding: 16px 40px !important;
    font-size: 1.5vw;
  }

  .drivenByDataText p {
    line-height: 125%;
  }
}

@media screen and (max-width: 768px) {
  .drivenByDataTitle {
    font-size: 3.5vw !important;
  }

  .drivenByDataText p {
    font-size: 2.5vw;
    line-height: 140%;
  }

  .btn {
    padding: 22px 45px !important;
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 576px) {
  .drivenByDataTitle {
    font-size: 3.7vw !important;
  }

  .drivenByDataText p {
    font-size: 2.7vw;
  }

  .btn {
    padding: 18px 45px !important;
    font-size: 3.2vw;
  }
}
