/* Support */
.devopsBanner {
  position: relative;
  font-size: 10px;
}

.image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);

  /* center overlay text */
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
}

.overlay .text {
  padding: 0 25px;
  font-size: 3.2vw;
  font-family: "Poppins", sans-serif !important;
  color: #fff;
  font-family: inherit;
}

.excellenceTitle {
  font-family: "Poppins", sans-serif;
  font-weight: 300 !important;
  font-size: 2.9em !important;
}

.devops_row {
  padding: 0 55px 0 55px;
  margin: 80px 0 30px 0;
}

@media screen and (max-width: 1200px) {
  .excellenceTitle {
    font-size: 2.7em !important;
  }

  .devops_row {
    margin: 60px 0 30px 0;
  }
}

@media screen and (max-width: 992px) {
  .excellenceTitle {
    font-size: 4.5vw !important;
  }

  .devops_row {
    margin: 50px 0 20px 0;
  }
}

@media screen and (max-width: 768px) {
  .devops_row {
    margin: 40px 0 20px 0;
  }
}

@media screen and (max-width: 576px) {
  .devops_row {
    margin: 20px 0 5px 0;
  }
}
