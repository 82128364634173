.image {
  width: 100%;
  height: auto;
}
.containerStyle {
  position: relative;
  text-align: center;
}
.textStyle {
  font-weight: 700;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
}
.outsourcingHeading {
  text-decoration: underline;
  font-size: 1.5em;
  font-weight: 400;
}
.sideText {
  padding: 5px 5px 5px 15px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 4px 2px rgba(131, 255, 176, 0.25),
    0px 0px 5px 0px rgba(0, 0, 0, 0.25) inset;
  display: flex;
  justify-content: flex-start;
}
.ideasImg {
  width: 80%;
  height: 80%;
}
