.caseStudycard {
  width: 300px;
  height: 309.73px;
  margin: 10px;
  border-radius: 25px;
  border: 3px solid #004952;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25),
    0px 0px 8px 0px rgba(0, 0, 0, 0.25) inset;
    font-family: 'Poppins',sans-serif;
}

.allCards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-family: 'Poppins',sans-serif;

}
.successstoriesTitle {
  text-decoration: underline;
  font-size: 2em;
  font-weight: 400;
  font-family: 'Poppins',sans-serif;
}
